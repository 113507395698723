$(document).ready(function(){
    // CUSTOM SELECT 
    $(".custom-dropdown").each(function() {
        var classes = $(this).attr("class"),
            id = $(this).attr("id"),
            name = $(this).attr("name");
        var template = '<div class="' + classes + '">';
        template += '<span class="custom-dropdown-trigger">' + $(this).attr("placeholder") + '</span>';
        template += '<div class="custom-options">';
        $(this).find("option").each(function() {
            template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
        });
        template += '</div></div>';
        $(this).wrap('<div class="custom-dropdown-wrapper"></div>');
        $(this).hide();
        $(this).after(template);
    });

    $(".custom-option:first-of-type").hover(function() {
        $(this).parents(".custom-options").addClass("option-hover");
    }, function() {
        $(this).parents(".custom-options").removeClass("option-hover");
    });
    $(".custom-dropdown-trigger").on("click", function() {
        $('html').one('click', function() {
            $(".custom-dropdown").removeClass("opened");
        });
        $(this).parents(".custom-dropdown").toggleClass("opened");
        event.stopPropagation();   
    });
    $(".custom-option").on("click", function() {    
        $(this).parents(".custom-dropdown-wrapper").find("select").val($(this).data("value"));
        $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
        $(this).addClass("selection");
        $(this).parents(".custom-dropdown").removeClass("opened");
        $(this).parents(".custom-dropdown").find(".custom-dropdown-trigger").text($(this).text());
    });
    
})


require('jquery-countdown/dist/jquery.countdown');

require('summernote/dist/summernote-bs4');